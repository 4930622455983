import { environment } from "src/environments/environment";
import { Injectable, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// [[Providers]]
import { DateService } from "~services/date.service";
// ]
@Injectable()
export class ErrorService {
  constructor(public http: HttpClient, public dateService: DateService) {}

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == "object") {
        if (data[entry] == null) {
          postData[entry] = null;
        } else if (Object.prototype.toString.call(data[entry]) === "[object Date]") {
          postData[entry] = this.dateService.convertDatePickerTimeToMySQLTime(data[entry]);
        } else {
          postData[entry] = data[entry];
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param err
   */
  throw(originalHttpError: any) {
    let errors = [];

    if (originalHttpError.error.errors) {
      for (let error in originalHttpError.error.errors) {
        errors.push(originalHttpError.error.errors[error]);
      }
    } else {
      if (originalHttpError.status == 500) {
        errors.push("Infelizmente a aplicação respondeu de forma inesperada. Por favor, tente novamente. Se o problema persistir contate nosso suporte.");
      }
      if (originalHttpError.status == 404) {
        errors.push("Os recursos dessa página não foram localizados.");
      }
    }

    var finally_error = {
      status: originalHttpError.status,
      url: originalHttpError.url,
      messageServer: originalHttpError.message,
      message: originalHttpError.error.message,
      errors: errors,
    };

    this.postThrow(finally_error);

    return finally_error;
  }

  /**
   * Posts throw
   * @param data
   */
  postThrow(data: any) {
    let postData = this.getPost(data);
    this.http
      .post(environment.http_garbage_collection, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .subscribe(
        (response: any) => {},
        (error) => {}
      );
  }
}

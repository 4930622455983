import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SEO {
  constructor(private metaService: Meta, private titleService: Title) {}

  /**
   * Sets title
   * @param metaTags
   */
  setTitle(metaTags: any) {
    if (metaTags.hasOwnProperty("title")) {
      this.titleService.setTitle(metaTags.title);
    }
  }

  /**
   * Sets meta
   * @param metaTags
   */
  setMeta(metaTags: any) {
    if (metaTags.hasOwnProperty("keywords")) {
      this.metaService.updateTag({ name: "keywords", content: metaTags.keywords });
    }
    if (metaTags.hasOwnProperty("description")) {
      this.metaService.updateTag({
        name: "description",
        content: metaTags.description,
      });
    }
    if (metaTags.hasOwnProperty("og_url")) {
      this.metaService.updateTag({
        name: "og:url",
        content: metaTags.og_url,
      });
    }
    if (metaTags.hasOwnProperty("og_title")) {
      this.metaService.updateTag({
        name: "og:title",
        content: metaTags.og_title,
      });
    }
    if (metaTags.hasOwnProperty("og_type")) {
      this.metaService.updateTag({
        name: "og:type",
        content: metaTags.og_type,
      });
    }
    if (metaTags.hasOwnProperty("og_description")) {
      this.metaService.updateTag({
        name: "og:description",
        content: metaTags.og_description,
      });
    }
    if (metaTags.hasOwnProperty("og_image")) {
      this.metaService.updateTag({
        name: "og:image",
        content: metaTags.og_image,
      });
    }
  }

  setMetax() {
    // this.titleService.setTitle(this.metaTags.title);
    // this.metaService.updateTag({ name: "keywords", content: this.metaTags.keywords });
    // this.metaService.updateTag({
    //   name: "description",
    //   content: this.metaTags.keywords,
    // });
    // this.metaService.updateTag({
    //   name: "og:url",
    //   content: this.metaTags.og_url,
    // });
    // this.metaService.updateTag({
    //   name: "og:title",
    //   content: this.metaTags.og_title,
    // });
    // this.metaService.updateTag({
    //   name: "og:type",
    //   content: this.metaTags.og_type,
    // });
    // this.metaService.updateTag({
    //   name: "og:description",
    //   content: this.metaTags.og_description,
    // });
    // this.metaService.updateTag({
    //   name: "og:image",
    //   content: this.metaTags.og_image,
    // });
    // https://schema.org/Productgit
    // this.product_schema = {
    //   "@context": "http://schema.org",
    //   "@type": "Product",
    //   name: this.product.name,
    //   image: [this.product.img_spotlight],
    //   url: "https://" + document.domain + "/p/" + this.product.url_handle + "/id/" + this.product.id,
    //   description: this.product.body_description_start,
    //   sku: this.product.code_sku,
    //   mpn: this.product.code_mpn,
    //   datePublished: "",
    //   brand: {
    //     "@type": "Brand",
    //     name: this.product.salesman != null ? this.product.salesman.name : "",
    //   },
    //   // review: {
    //   //   "@type": "Review",
    //   //   reviewRating: {
    //   //     "@type": "Rating",
    //   //     ratingValue: "4",
    //   //     bestRating: "5",
    //   //   },
    //   //   author: {
    //   //     "@type": "Person",
    //   //     name: "Fred Benson",
    //   //   },
    //   // },
    //   // aggregateRating: {
    //   //   "@type": "AggregateRating",
    //   //   ratingValue: "4.4",
    //   //   reviewCount: "89",
    //   // },
    //   offers: {
    //     "@type": "Offer",
    //     priceCurrency: "BRL",
    //     price: this.product.price,
    //     url: "https://" + document.domain + "/p/" + this.product.url_handle + "/id/" + this.product.id,
    //     itemCondition: "https://schema.org/UsedCondition",
    //     availability: "https://schema.org/InStock",
    //   },
    // };
  }
}

import { environment } from 'src/environments/environment';
import { Component, OnInit, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
// [[Providers]]
import { CustomerUserService } from '~project/services/panel/customer/user/user.service';
// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-guard-footer',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
})
export class GuardFooterComponent implements OnDestroy {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;

  private renderer: Renderer2;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router,
    private rendererFactory: RendererFactory2,
    /**
     * Base de uso em Component
     */
    private userService: CustomerUserService
    /**
     * Services
     */
  ) {}

  currentRoute: string = '';
  ngOnInit(): void {
    this.ipBuildAppDependencies();
  }

  /**
   * build ecommerce dependencies
   */
  ipBuildAppDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
    // PROFILE
    this.userService.getUser().subscribe((Profile: any) => {
      if (Profile != null) {
        if (JSON.parse(Profile) != null) {
          this.profile = JSON.parse(Profile);
        }
      }
    });
  }

  /**
   * after view init
   */
  ngAfterViewInit() {}

  /**
   * on destroy
   */
  ngOnDestroy() {}
}

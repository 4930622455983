import { environment } from 'src/environments/environment';
import { Component, AfterViewInit, ViewChild, ElementRef, HostListener, Input, ComponentFactoryResolver } from '@angular/core';
import { fromEvent } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
// [[Providers]]
import { CustomerUserService } from '~project/services/panel/customer/user/user.service';
// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-guard-header',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class GuardHeaderComponent {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;

  favicon: HTMLLinkElement = document.querySelector('#app_icon');

  actualUrl: any;
  openMenuMobile: any = false;
  openSubMenuMobile: any = false;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private cookieService: CookieService,
    /**
     * Base de uso em Component
     */
    private userService: CustomerUserService
    /**
     * Services
     */
  ) {
    /**
     * Profile
     *
     * Request inicial do PROFILE para acompanhamento nas Páginas.
     */
    this.userService.profile().subscribe((Profile: any) => {
      if (Profile != null) {
        this.userService.setUser(Profile.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
    //
    this.actualUrl = this.router.url;
  }

  /**
   * on init
   */
  ngOnInit() {
    this.ipBuildAppDependencies();
  }

  /**
   * build ecommerce dependencies
   */
  ipBuildAppDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
    // PROFILE
    this.userService.getUser().subscribe((Profile: any) => {
      if (Profile != null) {
        if (JSON.parse(Profile) != null) {
          this.profile = JSON.parse(Profile);
        }
      }
    });
  }

  /**
   * after view init
   */
  ngAfterViewInit() {}

  /**
   * Collectives customer user service logout
   * @param login
   */
  collectiveCustomerUserServiceLogout(login: any) {
    this.logoutUser();

    let _ = this.userService.logout(login);
    if (_ !== null) {
      _.subscribe(
        () => {
          document.location.href = '/';
        },
        () => {
          document.location.href = '/';
        }
      );
    }
  }

  logoutUser() {
    localStorage.clear();
    // Utilização de Cookie
    this.cookieService.delete('customer_user_tk', '/', environment.cookie_domain);
    this.cookieService.delete('customer_user_id', '/', environment.cookie_domain);
  }
}

import { Injectable } from "@angular/core";
import { Md5 } from "ts-md5/dist/md5";

@Injectable()
export class CacheService {
  constructor() {}

  // Retrieving Items From The Cache
  get(DATA_CACHE_INDEX: string, CACHE_TIME?: number) {
    DATA_CACHE_INDEX = "__data_app_cache_" + Md5.hashStr(DATA_CACHE_INDEX);

    // -------------------------------------------------------------------------
    let currentdate = new Date();
    // -------------------------------------------------------------------------
    if (typeof CACHE_TIME != "undefined") {
      CACHE_TIME = 0;
    }
    var CACHE = JSON.parse(sessionStorage.getItem(DATA_CACHE_INDEX));
    if (CACHE !== null) {
      if (CACHE.expires > currentdate.getTime()) {
        if (CACHE.data_in_cache) {
          return CACHE.data_in_cache;
        }
      } else {
        this.forget(DATA_CACHE_INDEX);
      }
    }
    return null;
  }

  // Storing Items In The Cache
  put(DATA_CACHE_INDEX: string, DATA: any, CACHE_TIME?: number): Boolean {
    DATA_CACHE_INDEX = "__data_app_cache_" + Md5.hashStr(DATA_CACHE_INDEX);

    // -------------------------------------------------------------------------
    let currentdate = new Date();
    // -------------------------------------------------------------------------
    if (typeof CACHE_TIME != "undefined") {
      if (CACHE_TIME > 0) {
        sessionStorage.setItem(DATA_CACHE_INDEX, JSON.stringify({ data_in_cache: DATA, expires: currentdate.getTime() + CACHE_TIME * 1000 }));
      }
    }
    return true;
  }

  // Removing Items From The Cache
  forget(DATA_CACHE_INDEX: string) {
    DATA_CACHE_INDEX = "__data_app_cache_" + Md5.hashStr(DATA_CACHE_INDEX);

    sessionStorage.removeItem(DATA_CACHE_INDEX);
  }

  // Removing Items From The Cache by Key separated by -
  flushBy(KEY?: string) {
    for (let i = 0; i < sessionStorage.length; i++) {
      let SESSION_KEY = sessionStorage.key(i).split("-");
      if (SESSION_KEY[1] == KEY) {
        sessionStorage.removeItem(sessionStorage.key(i));
      }
    }
  }

  flush() {
    sessionStorage.clear();
  }
}

import { environment } from 'src/environments/environment';
import { Component, OnInit, Input } from '@angular/core';
// [[Providers]]

// ]
// [[Interfaces do Componente]]

// ]
@Component({
  selector: 'app-guard-menu-left',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
})
export class GuardLayoutMenuLeftComponent implements OnInit {
  /**
   * Base de uso em Component
   */
  public environment = environment;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}

  /**
   * The function is called after the view is initialized
   */
  ngAfterViewInit() {
    this.burgerMenuByDevice();
  }

  burgerMenuIsExpanded: boolean = true;
  /**
   * Burgers menu
   */
  burgerMenu(forceBurgerMenuStatus: any = null) {
    // MENU
    if (forceBurgerMenuStatus === null) {
      this.burgerMenuIsExpanded = !this.burgerMenuIsExpanded;
    } else {
      this.burgerMenuIsExpanded = forceBurgerMenuStatus;
    }

    var elements: any = document.getElementsByClassName('menu-left') as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < elements.length; i++) {
      // Recolhe o Menu
      if (!this.burgerMenuIsExpanded) {
        elements[i].classList.add('w-12');
      }

      // Expande o Menu
      if (this.burgerMenuIsExpanded) {
        elements[i].classList.remove('w-12');
      }
    }
  }

  /**
   * Burgers menu by device
   */
  burgerMenuByDevice() {
    const userAgent = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
      this.burgerMenu(false);
    }
  }

  burguerMenuSaveStatus() {}
}

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateService {
  constructor() {}

  /**
   * Converts date picker time to my sqltime
   * @param str
   * @returns
   */
  convertDatePickerTimeToMySQLTime(str: any) {
    var date: any, year: string, month: string, day: string, hours: string, minutes: string, seconds: string;
    (date = new Date(str)), (month = ('0' + (date.getMonth() + 1)).slice(-2)), (day = ('0' + date.getDate()).slice(-2));
    hours = ('0' + date.getHours()).slice(-2);
    minutes = ('0' + date.getMinutes()).slice(-2);
    seconds = ('0' + date.getSeconds()).slice(-2);

    var mySQLDate = [date.getFullYear(), month, day].join('-');
    var mySQLTime = [hours, minutes, seconds].join(':');

    return [mySQLDate, mySQLTime].join(' ');
  }

  /**
   * Ranges picker
   * @returns
   */
  rangePicker() {
    return [
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
        label: 'Últimos 7 dias',
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 15)), new Date()],
        label: 'Últimos 15 dias',
      },
      {
        value: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)],
        label: 'Este Mês',
      },
      {
        value: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 3, 0)],
        label: 'Último Trimestre',
      },
      {
        value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 12, 0)],
        label: 'Este Ano',
      },
    ];
  }

  rangeDate(range: any = 2) {
    return this.rangePicker()[range].value;
  }

  /**
   * Firsts range picker
   * @returns
   */
  firstRangePicker() {
    return this.rangePicker()[0];
  }
}
